a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

#semantic-menu {
  background-color: #dbc3a5;
}

// .layout {
//   background-color: #eae7dc;
//   flex: 1 1 0%;
// }
// $eggshell: #eae7dc;
// $dark-vanilla: #dbc3a5;
// $taupe-gray: #8e8d8a;
// $candy-pink: #e98074;
// $jellybean-red: #e85a4f;
