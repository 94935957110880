@import "./assets/stylesheets/application.scss";
// @import "./components/**/*.scss";

.flex-one {
  flex: 1 1 0;
}

.space-between-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
