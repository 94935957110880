// Default semantic styling
.default-button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.1s ease, background-color 0.1s ease,
    color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
  will-change: "";
  -webkit-tap-highlight-color: transparent;
}
//Colors, note that secondary is from the pallate, not a generic grey
.default-button.disabled {
  filter: brightness(125%);
}

.default-button.fluid {
  width: 100%;
}

.btn.vanilla {
  background-color: $dark-vanilla;
  color: darkslategrey;
}
.btn.pink {
  background-color: $candy-pink;
  color: white;
}
.btn.red {
  background-color: $jellybean-red;
  color: white;
}
.btn.taupe {
  background-color: $taupe-gray;
  color: white;
}

.btn.green {
  background-color: #21ba45;
  color: white;
}
.btn.blue {
  background-color: #0069c0;
  color: white;
}
.btn.grey {
  background-color: lightgrey;
  color: darkslategrey;
}

.clickable {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
