// Stealing from #19 https://visme.co/blog/website-color-schemes/

$eggshell: #eae7dc;
$dark-vanilla: #dbc3a5;
$taupe-gray: #8e8d8a;
$candy-pink: #e98074;
$jellybean-red: #e85a4f;

// .h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   color: $jellybean-red;
// }

.questionnaire-container {
  display: flex;
  flex: 1;
  margin: 25px;
}
.questionnaire-question-answer {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  //   background-color: $eggshell;
  border: 2px solid $jellybean-red;
}

.questionnaire-radio-container {
  margin: 10px 20px;
}
