//Edit Company
// .semantic-header-row > th {
//   background-color: red !important;
//   color: antiquewhite !important;
// }
.edit-company-address-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.edit-company-address-container > * {
  width: 48%;
}
