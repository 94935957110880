.login-screen {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: #eae7dc;
}
.login-container {
  width: 50vh;
  display: flex;
  margin-top: 8em;
  justify-content: flex-start;
  flex-direction: column;
}

#login-button {
  background-color: #e85a4f;
  color: white;
}
