@import "./colors";
@import "./typography";
@import "./buttons";

.layout {
  height: 100%;
  flex-direction: column;
  display: flex;
  flex: 1;
}

body {
  height: 100%;
  // overflow: auto;
  background-color: #eae7dc;
}

.semantic-header-row > th {
  background-color: $candy-pink !important;
  color: white !important;
}
