.create-subscription-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
}
.create-subscription-form > div {
  margin: 5px 1px;
}
