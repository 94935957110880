.ui.card.outreach-card {
  padding: 15px;
}

.cycle-button-group {
  display: flex;
  justify-content: space-around;
}
.cycle-button-group > button {
  width: 40%;
}
.outreach-cycle-group {
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
  align-items: flex-end;
}
