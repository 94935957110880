// @import "../../assets/stylesheets/colors";
// .ui.card.campaign-card {
//   padding: 15px;
//   width: 650px;
// }

// .active-card {
//   animation: expandDiv 1s forwards;
// }
// @keyframes expandDiv {
//   to {
//     width: 100%;
//     // always put margin on the remaining 5% on the right
//     // width: 95%;
//     margin: 5%, 0;
//   }
// }

.ui.card.campaign-card {
  width: 100%;
  margin: 5%, 0;
  padding: 15px;
}

.subscription-form > div {
  margin: 10px 0px;
}

.overflow {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}
// div.divider.text {
//   text-overflow: ellipsis;

//   /* Required for text-overflow to do anything */
//   white-space: nowrap;
//   overflow: hidden;
// }

.request-question {
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid lightgrey;
  border-radius: 1em;
  box-shadow: 0 3px 4px rgb(0 0 0 / 0.2);
}

.campaign-opening {
  padding: 5px;
  margin: 10px;
  // border: 1px solid #dbc3a5;

  // background-color: #eae7dc;
  max-width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  // width: 290px;
  min-height: 0;
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 3px 0 #8e8d8a, 0 0 0 1px #8e8d8a;
  -webkit-transition: box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  transition: box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  transition: box-shadow 0.1s ease, transform 0.1s ease;
  transition: box-shadow 0.1s ease, transform 0.1s ease,
    -webkit-transform 0.1s ease;
}

.request-question > div {
  margin: 5px 0px;
}

//test-campaign.jsx
.test-ivr-form {
  display: flex;
  justify-content: flex-end;
}
.customer-dropdown {
  display: flex;
  align-items: center;
  margin-right: 10px;
  width: 100%;
}

//edit-request.jsx
// For drag and drop sorting
.droppable-answers {
  padding: 10px;
}

.date-selection-container {
  display: flex;
}
.date-selection-container > div {
  margin-right: 5px;
}

// Create Question Modal
//Why do I have to do this lol
#create-question-modal {
  top: 10%;
}
// $eggshell: #eae7dc;
// $dark-vanilla: #dbc3a5;
// $taupe-gray: #8e8d8a;
// $candy-pink: #e98074;
// $jellybean-red: #e85a4f;

// Request Details

.campaign-question-group {
  padding: 5px;
  margin: 10px;
  // border: 1px solid #dbc3a5;

  // background-color: #eae7dc;
  max-width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  // width: 290px;
  min-height: 0;
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 3px 0 #8e8d8a, 0 0 0 1px #8e8d8a;
  -webkit-transition: box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  transition: box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  transition: box-shadow 0.1s ease, transform 0.1s ease;
  transition: box-shadow 0.1s ease, transform 0.1s ease,
    -webkit-transform 0.1s ease;
}

.tasks-label-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

// Edit Question
.card-header {
  display: block;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  background: #fff;
  margin: 0;
  padding: 1.25rem 1.5rem;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

#create-answer-modal {
  top: 10%;
}
#edit-answer-modal {
  top: 10%;
}
